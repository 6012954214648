import { Box } from "@mui/material";

import Loading from "@sellernote/_shared/src/componentsToMoveToV1/Loading";
import { ForwardingAdminUserListItem } from "@sellernote/_shared/src/types/forwarding/adminUser";
import { TrelloBidList } from "@sellernote/_shared/src/types/forwarding/trello";

import { OpCheckPointIndexSignature } from "../../../../types/trello";

import TrelloCardList from "../../_components/list/TrelloCardList";
import ListHeadFilter from "../../_components/ListHeadFilter";
import useCommonTrelloListLogic from "../../_hooks/useCommonTrelloListLogic";
import useHandleTrelloCardOpCheckPoint from "../../_hooks/useHandleTrelloCardOpCheckPoint";
import useImportCardListData from "../../_hooks/useImportCardListData";
import useRefreshTrelloListForListPage from "../../_hooks/useRefreshTrelloListForListPage";
import RefreshButton from "../RefreshButton";
import CheckIsPaymentModal from "./CheckPaymentModal";
import DetailModal from "./DetailModal";

/**
 * 서브메뉴는 General, Consolidation으로 나누지만 API 호출 타입만 다르고 내부 로직은 동일.
 */
const ShipdaGeneralAndConsolidationTrello = ({
  trelloListData,
  adminData,
  isLoading,
  refetchData,
}: {
  trelloListData?: TrelloBidList[];
  adminData?: ForwardingAdminUserListItem[];
  isLoading: boolean;
  refetchData: () => void;
}) => {
  const {
    sessionStorageTrelloIssueFilter,
    showsTrelloDetailModal,
    bidId,
    showsCheckIsPaymentModal,
    cardEndAddress,
    handleTrelloCardClick,
    serviceType,
    salesNameList,
    handleSessionStorageTrelloIssueFilterChange,
    setShowsTrelloDetailModal,
    openTrelloCardDetail,
    fetchTrelloListParams,
    setShowsCheckIsPaymentModal,
  } = useCommonTrelloListLogic({ adminData });

  const { refreshTrelloListForListPage } =
    useRefreshTrelloListForListPage<OpCheckPointIndexSignature>({
      trelloListData: trelloListData ?? [],
      fetchTrelloListParams,
      serviceType:
        serviceType === "consolidation" ? "consolidation" : undefined,
    });

  const { handleTrelloCardOpCheckPoint } = useHandleTrelloCardOpCheckPoint({
    refreshTrelloListForListPage,
  });

  const { importCardListData } = useImportCardListData({
    trelloListData: trelloListData ?? [],
    handleTrelloCardClick,
    handleTrelloCardOpCheckPoint,
    sessionStorageTrelloIssueFilter,
  });

  return (
    <>
      <Box
        display="flex"
        paddingLeft="16px"
        overflow="auto"
        alignItems="center"
      >
        <RefreshButton refetchData={refetchData} />

        <ListHeadFilter
          salesNameList={salesNameList ?? []}
          sessionStorageTrelloIssueFilter={sessionStorageTrelloIssueFilter}
          onSessionStorageTrelloIssueFilterChange={
            handleSessionStorageTrelloIssueFilterChange
          }
        />
      </Box>

      {isLoading ? (
        <Box
          display="flex"
          overflow="auto"
          padding={1}
          position="relative"
          height="calc(100vh - 130px)"
        >
          <Loading active={true} uiType="parentBy" />
        </Box>
      ) : (
        <TrelloCardList cardListData={importCardListData} />
      )}

      {showsTrelloDetailModal && (
        <DetailModal
          showsTrelloDetailModal={showsTrelloDetailModal}
          setShowsTrelloDetailModal={setShowsTrelloDetailModal}
          bidId={bidId}
          trelloListData={trelloListData}
          fetchTrelloListParams={fetchTrelloListParams}
        />
      )}

      {showsCheckIsPaymentModal && (
        <CheckIsPaymentModal
          onTrelloDetailOpen={openTrelloCardDetail}
          showCheckIsPaymentModal={showsCheckIsPaymentModal}
          setShowCheckIsPaymentModal={setShowsCheckIsPaymentModal}
          bidId={bidId}
          endAddress={cardEndAddress}
        />
      )}
    </>
  );
};

export default ShipdaGeneralAndConsolidationTrello;

import { Dispatch, SetStateAction } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { red } from "@mui/material/colors";

import Modal from "@sellernote/_shared-for-admin/src/components/Modal";

function ShipmentStatusChangeConfirmModal({
  setShowsShipmentStatusChangeConfirmModal,
  showsShipmentStatusChangeConfirmModal,
  onConfirm,
  isLoading,
  content,
}: {
  setShowsShipmentStatusChangeConfirmModal: Dispatch<SetStateAction<boolean>>;
  showsShipmentStatusChangeConfirmModal: boolean;
  onConfirm: () => void;
  isLoading?: boolean;
  content: string;
}) {
  return (
    <Modal
      isOpened={showsShipmentStatusChangeConfirmModal}
      handleClose={() => setShowsShipmentStatusChangeConfirmModal(false)}
      modalBody={
        <Box>
          <Typography variant="body2" component="div" sx={{ color: red[700] }}>
            {content}
          </Typography>

          <Box display={"flex"} justifyContent={"center"} gap={1} mt={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={onConfirm}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={25} /> : "예"}
            </Button>

            <Button
              variant="contained"
              color="success"
              onClick={() => setShowsShipmentStatusChangeConfirmModal(false)}
            >
              아니오
            </Button>
          </Box>
        </Box>
      }
    />
  );
}

export default ShipmentStatusChangeConfirmModal;

import { useCallback } from "react";
import { useQueryClient } from "react-query";

import TRELLO_BID_QUERY, {
  TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";
import {
  ExportTrelloList,
  FetchTrelloListParams,
  TrelloBidDetail,
  TrelloBidList,
} from "@sellernote/_shared/src/types/forwarding/trello";

import useGetTrelloQueryKeyGen from "./useGetTrelloQueryKeyGen";

/**
 * 트렐로 상세 모달 종료 후 리스트 데이터 갱신을 위한 커스텀 훅
 *   - 트렐로 리스트의 요청 시간이 오래걸리기 때문에 재요청하지 않고 해당 데이터만 찾아서 쿼리 데이터를 업데이트 해준다.
 */
export default function useRefreshTrelloListForDetailModal({
  trelloListData,
  fetchTrelloListParams,
  isImport,
  trelloDetailData,
}: {
  trelloListData?: TrelloBidList[] | ExportTrelloList[];
  fetchTrelloListParams?: FetchTrelloListParams;
  isImport: boolean;
  trelloDetailData: TrelloBidDetail | undefined;
}) {
  const queryClient = useQueryClient();

  const { mutate: confirmShipmentSchedule } =
    TRELLO_BID_QUERY.useConfirmShipmentSchedule();

  const {
    importationTrelloListQueryKey,
    exportationTrelloListQueryKey,
    importationTrelloFinishedListQueryKey,
    exportationTrelloFinishedListQueryKey,
  } = useGetTrelloQueryKeyGen({
    fetchTrelloListParams,
    serviceType:
      trelloDetailData?.serviceType === "consolidation"
        ? "consolidation"
        : undefined,
  });

  const refreshTrelloListForDetailModal = useCallback(() => {
    if (trelloListData && trelloDetailData) {
      /** concat으로 합쳐진 진행중 + 종료 리스트 데이터에서 분리 */
      const inProgressTrelloList = [...trelloListData].filter((v) => {
        return v.projectStatus !== "finished";
      });

      /** 상세 데이터가 종료 상태인 의뢰인 경우 */
      if (trelloDetailData?.projectStatus === "finished") {
        /** 종료 요청으로 진행중 => 종료가 된 경우 리스트 데이터는 갱신이 되지 않았기 때문에 진행중 리스트 데이터에 있는지 확인 */
        const staleDetailData = inProgressTrelloList.find((v) => {
          return v.id === trelloDetailData.id;
        });

        if (staleDetailData) {
          /** 갱신 안된 데이터가 있다면 진행중 리스트에서 제거 */
          const updatedTrelloListData = inProgressTrelloList.filter(
            (trelloData) => {
              return trelloData.id !== trelloDetailData.id;
            }
          );

          /** 진행중 리스트 쿼리를 재갱신 */
          queryClient.setQueryData(
            isImport
              ? importationTrelloListQueryKey
              : exportationTrelloListQueryKey,
            { data: updatedTrelloListData }
          );
        }

        /** 종료 리스트 재요청 */
        queryClient.invalidateQueries(
          isImport
            ? importationTrelloFinishedListQueryKey
            : exportationTrelloFinishedListQueryKey
        );

        return;
      }

      /** 진행중 리스트에서 변경할 특정 데이터를 찾는다. */
      const targetDataIndex = inProgressTrelloList.findIndex((v) => {
        return v.id === trelloDetailData.id;
      });

      /** 만약 인덱스를 찾지 못한다면 전체 데이터를 다시 요청(정산 요청을 하게 되는 경우 정산 테이블로 빠지게 되는 경우가 있음) find에서 찾지 못 할 경우 -1를 리턴 */
      if (targetDataIndex === -1) {
        queryClient.invalidateQueries(TRELLO_BID_QUERY_KEY_GEN.trelloBidList());
        return;
      }

      /** 상세와 리스트 데이터를 매칭 */
      inProgressTrelloList[targetDataIndex] = {
        accountPayables: trelloDetailData.accountPayables,
        attachmentFlag: inProgressTrelloList[targetDataIndex]?.attachmentFlag,
        attachments: inProgressTrelloList[targetDataIndex].attachments,
        containCustoms: trelloDetailData.containCustoms,
        endAddress: trelloDetailData.endAddress,
        endAddressDetail: trelloDetailData.endAddressDetail,
        endCountry: trelloDetailData.endCountry,
        invoices: inProgressTrelloList[targetDataIndex].invoices,
        freightType: trelloDetailData.freightType,
        id: trelloDetailData.id,
        incoterms: trelloDetailData.incoterms,
        /** 파란색 테두리를 없애기 위해 의도적으로 null 처리, 실제 API는 line 202에서 요청 */
        management: {
          ...trelloDetailData.management,
          externalApiUpdatedAt: null,
        },
        poNumber: inProgressTrelloList[targetDataIndex].poNumber,
        projectStatus: trelloDetailData.projectStatus,
        serviceType: trelloDetailData.serviceType,
        startAddress: trelloDetailData.startAddress,
        startCountry: trelloDetailData.startCountry,
        team: trelloDetailData.team,
        region: trelloDetailData.locale,
        isImport: trelloDetailData.isImport,
        /** 수출에서는 프리타임 관련 데이터가 존재하지 않음 */
        ...(isImport && {
          freeTimeLFD: (inProgressTrelloList[targetDataIndex] as TrelloBidList)
            .freeTimeLFD,
        }),
        opCheckPoint: inProgressTrelloList[targetDataIndex].opCheckPoint,
        closingInvoice: inProgressTrelloList[targetDataIndex].closingInvoice,
      };

      queryClient.setQueryData(
        isImport
          ? importationTrelloListQueryKey
          : exportationTrelloListQueryKey,
        { data: inProgressTrelloList }
      );

      /** 파란색 테두리(유니패스, 마린트래픽, 선사에 의해 스케줄 변경)가 있는 경우 스케줄 변경 확인 API 호출 */
      if (trelloDetailData.management.externalApiUpdatedAt) {
        confirmShipmentSchedule(
          {
            pathParams: {
              shipmentId: trelloDetailData?.id,
            },
          },
          {
            onError: () =>
              queryClient.invalidateQueries(
                TRELLO_BID_QUERY_KEY_GEN.trelloBidList()
              ),
          }
        );
      }

      return;
    }
  }, [
    confirmShipmentSchedule,
    exportationTrelloFinishedListQueryKey,
    exportationTrelloListQueryKey,
    importationTrelloFinishedListQueryKey,
    importationTrelloListQueryKey,
    isImport,
    queryClient,
    trelloDetailData,
    trelloListData,
  ]);

  return {
    refreshTrelloListForDetailModal,
  };
}

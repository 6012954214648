import { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

import { FreeTimeError } from "@sellernote/_shared/src/api-interfaces/shipda-api/freeTime";
import TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";
import { TrelloBidDetail } from "@sellernote/_shared/src/types/forwarding/trello";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import FourChevronDownIcon from "@sellernote/_sds-v2/src/components/svgIcons/FourChevronDownIcon";
import FourChevronUpIcon from "@sellernote/_sds-v2/src/components/svgIcons/FourChevronUpIcon";
import FreeTimeTable from "@sellernote/_shared-for-forwarding/src/components/FreeTimeTable";

import ClearCautionForLFD from "./ClearCautionForLFD";

const FreeTime = ({ trelloDetail }: { trelloDetail: TrelloBidDetail }) => {
  const [errorInfo, setErrorInfo] = useState<FreeTimeError>();

  const { data: freeTime, ResponseHandler } =
    TRELLO_BID_QUERY.useGetTrelloFreeTime({
      shipmentId: trelloDetail.id,
      enabled: !!trelloDetail.id,
      onError: (failureInfo) => {
        if (!failureInfo) return;

        setErrorInfo({
          errorCode: failureInfo.errorCode,
          error: failureInfo.error,
        });
      },
      hidesFailureModal: true,
    });

  const [opens, setOpens] = useState(false);

  const handleToggle = () => setOpens(!opens);

  // freeTime 데이터가 있으면 expansion 이 열린상태가 default, 데이터가 없으면 닫힌상태가 default.
  useEffect(() => {
    if (freeTime) {
      setOpens(true);
    } else setOpens(false);
  }, [freeTime]);

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{ marginTop: "8px", backgroundColor: grey[100] }}
      >
        <Grid
          display="flex"
          alignItems="center"
          padding={1}
          gap={1}
          item
          xs={12}
        >
          <Grid
            display="flex"
            alignItems="center"
            gap={1}
            sx={{ cursor: "pointer" }}
            onClick={handleToggle}
            item
            xs={4}
          >
            <Typography
              variant="h6"
              fontWeight={700}
              lineHeight="22px"
              sx={{ wordBreak: "keep-all" }}
            >
              프리타임
            </Typography>

            {opens ? (
              <Box display="flex" alignItems="center" marginBottom="4px">
                <FourChevronUpIcon width={16} height={16} color={"black"} />
              </Box>
            ) : (
              <Box display="flex" alignItems="center" marginBottom="4px">
                <FourChevronDownIcon width={16} height={16} color={"black"} />
              </Box>
            )}
          </Grid>

          {freeTime && (
            <>
              <Grid item xs={8} display="flex" alignItems="center" gap={1}>
                <Typography
                  variant="body2"
                  display="block"
                  width="100%"
                  textAlign="right"
                >
                  최종 업데이트:{` `}
                  {toFormattedDate(freeTime?.updatedAt, "YYYY-MM-DD HH:mm:ss")}
                </Typography>

                <ClearCautionForLFD
                  shipmentId={trelloDetail.id}
                  isClearedCautionForLFD={freeTime.isFreeTimeChecking}
                />
              </Grid>
            </>
          )}
        </Grid>

        {opens && (
          <Grid item xs={12} padding={1} maxHeight="730px" overflow="auto">
            <FreeTimeTable
              containers={freeTime?.containers}
              isCombined={freeTime?.isCombined}
              errorInfo={errorInfo}
            />
          </Grid>
        )}
      </Grid>

      {ResponseHandler}
    </>
  );
};

export default FreeTime;

import { useCallback, useEffect } from "react";
import { Modal, Spin } from "antd";
import { useSetRecoilState } from "recoil";

import ADMIN_COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_COMMON_QUERY";
import PARTNER_QUERY from "@sellernote/_shared/src/queries/forwarding/PARTNER_QUERY";
import SHIP_QUERY from "@sellernote/_shared/src/queries/forwarding/SHIP_QUERY";
import TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";
import { FORWARDING_INVOICE_ATOMS } from "@sellernote/_shared/src/states/forwarding/invoice";
import {
  FetchTrelloListParams,
  TrelloBidList,
} from "@sellernote/_shared/src/types/forwarding/trello";
import { useWindowResize } from "@sellernote/_shared/src/utils/common/hook";
import { checkIsNull } from "@sellernote/_shared/src/utils/common/validation";

import ShipmentCommentList from "../../../TrelloDetail/components/ShipmentCommentList";

import BasicInfo from "../../../_components/detail/BasicInfo";
import CargoStatusTable from "../../../_components/detail/CargoStatusTable";
import FreeTime from "../../../_components/detail/FreeTime";
import Profit from "../../../_components/detail/Profit";
import PurchaseManagement from "../../../_components/detail/PurchaseManagement";
import SalesManagement from "../../../_components/detail/SalesManagement";
import ShipmentSchedule from "../../../_components/detail/ShipmentSchedule";
import ShipNameManagement from "../../../_components/detail/ShipNameManagement";
import TrelloModalExitDialog from "../../../_components/detail/TrelloModalExitDialog";
import UploadDocuments from "../../../_components/detail/UploadDocuments";
import useHandleDialog from "../../../_hooks/useHandleDialog";
import useRefreshTrelloListForDetailModal from "../../../_hooks/useRefreshTrelloListForDetailModal";

const DetailModal = ({
  showsTrelloDetailModal,
  setShowsTrelloDetailModal,
  bidId,
  trelloListData,
  fetchTrelloListParams,
}: {
  showsTrelloDetailModal: boolean;
  setShowsTrelloDetailModal: (value: boolean) => void;
  bidId: number | undefined;
  trelloListData?: TrelloBidList[];
  fetchTrelloListParams?: FetchTrelloListParams;
}) => {
  const [windowWidth] = useWindowResize();

  // 모달의 넓이를 최대한 넓게 해달라는 요청 반영
  const modalWidth = windowWidth - 50;

  const setTrelloCardId = useSetRecoilState(
    FORWARDING_INVOICE_ATOMS.ADMIN_TRELLO_CARD_ID
  );

  const { data: trelloDetailData } = TRELLO_BID_QUERY.useGetTrelloBidDetail({
    bidId,
  });

  const { data: shipList } = SHIP_QUERY.useGetShipList();

  const { data: exchangeRateList } = ADMIN_COMMON_QUERY.useGetExchangeRateList({
    all: true,
    countryTarget: trelloDetailData?.locale,
  });

  const { partnerList } = PARTNER_QUERY.useGetPartnerList({
    page: 0,
    enabled: true,
    pageFlag: false,
    region: trelloDetailData?.locale ?? "KR",
  });

  const { refreshTrelloListForDetailModal } =
    useRefreshTrelloListForDetailModal({
      trelloListData,
      fetchTrelloListParams,
      isImport: true,
      trelloDetailData,
    });

  const {
    opensDialog,
    handleDialogClose,
    handleDialogOpen,
    isShipmentScheduleChanged,
  } = useHandleDialog();

  const handleCloseModal = useCallback(() => {
    setShowsTrelloDetailModal(false);

    refreshTrelloListForDetailModal();
  }, [refreshTrelloListForDetailModal, setShowsTrelloDetailModal]);

  const { projectStatus, management } = trelloDetailData ?? {};

  // 출항완료 이후 상태인지 확인. (출항완료 포함)
  const isProjectStatusAfterMoving =
    projectStatus === "moving" ||
    projectStatus === "portEntryAndPrepareCustoms" ||
    projectStatus === "payment" ||
    projectStatus === "completeCustoms" ||
    projectStatus === "delivering" ||
    projectStatus === "finished" ||
    projectStatus === "inSettlement" ||
    projectStatus === "settlementRejected" ||
    projectStatus === "settlementConfirmed" ||
    projectStatus === "settlementSecondConfirmed" ||
    projectStatus === "settlementComplete";

  // BL 입력 완료 여부 확인 (mBL 값이 null 이 아닐때, 입력완료)
  const isCompleteBLInput = !checkIsNull(management?.mBL);

  useEffect(() => {
    setTrelloCardId(undefined);
  }, [setTrelloCardId]);

  if (!trelloDetailData || !shipList || !exchangeRateList || !partnerList) {
    return (
      <Modal
        destroyOnClose={true}
        style={{ fontFamily: "Roboto" }}
        bodyStyle={{ height: 800, overflow: "scroll" }}
        visible={showsTrelloDetailModal}
        width={modalWidth}
        onCancel={handleCloseModal}
        footer={null}
      >
        <Spin />
      </Modal>
    );
  }

  return (
    <>
      <Modal
        destroyOnClose={true}
        style={{ fontFamily: "Roboto" }}
        bodyStyle={{ height: 800, overflow: "scroll" }}
        visible={showsTrelloDetailModal}
        width={modalWidth}
        onCancel={() => {
          /** 스케줄 변경이 있으면, 모달을 닫을 건지 확인하는 Dialog가 노출 */
          if (isShipmentScheduleChanged) {
            return handleDialogOpen();
          }

          handleCloseModal();
        }}
        footer={null}
      >
        <BasicInfo trelloDetail={trelloDetailData} partnerList={partnerList} />

        <ShipNameManagement trelloDetail={trelloDetailData} />

        {isProjectStatusAfterMoving && isCompleteBLInput && (
          <FreeTime trelloDetail={trelloDetailData} />
        )}

        <ShipmentSchedule trelloDetail={trelloDetailData} />

        <CargoStatusTable trelloDetail={trelloDetailData} />

        <UploadDocuments trelloDetail={trelloDetailData} />

        <SalesManagement trelloDetailData={trelloDetailData} />

        <PurchaseManagement
          trelloDetailData={trelloDetailData}
          partnerList={partnerList}
          exchangeRateList={exchangeRateList}
        />

        <Profit trelloDetail={trelloDetailData} />

        <ShipmentCommentList trelloDetailData={trelloDetailData} />
      </Modal>

      <TrelloModalExitDialog
        opensDialog={opensDialog}
        onDialogClose={handleDialogClose}
        onModalClose={handleCloseModal}
      />
    </>
  );
};

export default DetailModal;

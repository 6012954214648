import { Button } from "@mui/material";

import { GET_CARGO_MANIFEST_RES as CargoManifestInfoProps } from "@sellernote/_shared/src/api-interfaces/shipda-api/trello";
import TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";

import useSnackbar from "../../../../../../../../../../hooks/useSnackbar";
import validateForm from "./utils";

export default function RequestButton({
  form,
  shipmentId,
  isEditMode,
  canSubmit,
  setCanSubmit,
  isImport,
}: {
  form: CargoManifestInfoProps;
  shipmentId: number;
  isEditMode: boolean;
  canSubmit: boolean;
  setCanSubmit: (value: boolean) => void;
  isImport: boolean;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const { mutate: requestCargoManifest } =
    TRELLO_BID_QUERY.useRequestCargoManifest({
      shipmentId,
      onSnackbarOpen: handleSnackbarOpen,
    });

  const handleCargoManifestInfoRequest = () => {
    if (!validateForm(form, handleSnackbarOpen, isImport)) {
      return;
    }

    requestCargoManifest(
      {},
      {
        onSuccess: () => {
          handleSnackbarOpen("적하목록을 성공적으로 제출했습니다.");
          setCanSubmit(false);
        },
      }
    );
  };

  return (
    <Button
      sx={{ ml: 1 }}
      variant="contained"
      size="small"
      onClick={handleCargoManifestInfoRequest}
      disabled={isEditMode || !canSubmit}
    >
      제출
    </Button>
  );
}

import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { useSetRecoilState } from "recoil";

import { APP_NAME } from "@sellernote/_shared/src/constants";
import { FORWARDING_INVOICE_ATOMS } from "@sellernote/_shared/src/states/forwarding/invoice";
import { AdminBidDetail } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { ForwardingAdminUserListItem } from "@sellernote/_shared/src/types/forwarding/adminUser";
import { BidStatus } from "@sellernote/_shared/src/types/forwarding/bid";
import { BID_STATUS_OPTION_LIST } from "@sellernote/_shared/src/utils/common/options";
import {
  changeBidProjectStatusNameToKr,
  changeExportProjectStatusNameToKr,
  checkHasTrelloCard,
} from "@sellernote/_shared/src/utils/forwarding/bid";
import { checkIsSettlement } from "@sellernote/_shared/src/utils/forwarding/trello";

import useSnackbar from "../../../../hooks/useSnackbar";

import ManagerHistoryModal from "./_components/ManagerHistoryModal";
import ShipmentStatusChangeConfirmModal from "./_components/ShipmentStatusChangeConfirmModal";
import useHandleManagerHistory from "./_hooks/useHandleManagerHistory";
import useShipmentStatusChange from "./_hooks/useShipmentStatusChange";

const boxLayoutStyle: SxProps<Theme> = {
  display: "flex",
  alignItems: "center",
  gap: "4px",
};

const ESTIMATING_AFTER_STATUS: BidStatus[] = [
  "waiting",
  "waitingForExporterInfo",
  "inProgress",
  "finished",
];

const BasicDesc = ({
  bidDetail,
  adminUserList,
}: {
  bidDetail: AdminBidDetail;
  adminUserList: ForwardingAdminUserListItem[];
}) => {
  const { handleSnackbarOpen } = useSnackbar();

  const setTrelloCardId = useSetRecoilState(
    FORWARDING_INVOICE_ATOMS.ADMIN_TRELLO_CARD_ID
  );

  const {
    opensManagerHistoryModal,
    setOpensManagerHistoryModal,
    handleManagerHistoryModalOpen,
    managerHistoryList,
  } = useHandleManagerHistory({
    teamId: bidDetail.team.id,
    shipmentId: bidDetail.id,
  });

  const {
    handleShipmentStatusChangeToCancel,
    showsShipmentStatusChangeToCancelModal,
    setShowsShipmentStatusChangeToCancelModal,

    handleShipmentStatusChangeToWaitingForExporterInfo,
    showsShipmentStatusChangeToWaitingForExporterInfoModal,
    setShowsShipmentStatusChangeToWaitingForExporterInfoModal,

    handleExportationShipmentStatusToInprogress,
    showsExportationShipmentStatusToInprogressModal,
    setShowsExportationShipmentStatusToInprogressModal,

    handleShipmentStatusChangeSelect,
  } = useShipmentStatusChange({
    shipmentId: bidDetail.id,
    isImportation: bidDetail.isImport,
  });

  const history = useHistory();

  const isImport = bidDetail.isImport;

  const managerInCharge = (() => {
    if (!bidDetail.team) return "-";

    const targetId = isImport
      ? bidDetail.team.forwardingManagerId
      : bidDetail.team.exportForwardingManagerId;

    const manager = adminUserList.find(({ id }) => id === targetId);

    return manager?.name ?? "-";
  })();

  const handleMoveToTrelloPageClick = () => {
    if (checkHasTrelloCard(bidDetail.projectStatus)) {
      setTrelloCardId(bidDetail.id);

      if (
        checkIsSettlement(bidDetail.projectStatus) &&
        APP_NAME === "shipda-admin"
      ) {
        history.push("/settlement");
        return;
      }

      if (bidDetail.serviceType === "consolidation") {
        history.push("/trello/consolidation");
        return;
      }

      // 수출의뢰 일때는 수출 트렐로로 이동
      if (!bidDetail.isImport) {
        history.push("/trello/export");
        return;
      }

      history.push("/trello/general");
      return;
    }

    handleSnackbarOpen("트렐로 카드가 없는 의뢰입니다.", "warning");
    return;
  };

  const handleMoveToUserDetailPageClick = useCallback(() => {
    if (bidDetail.team.isPrivate) {
      history.push(`/users/list/${bidDetail.userId}`);
      return;
    }
    history.push(`/team/${bidDetail.team.id}`);
  }, [bidDetail.team.id, bidDetail.team.isPrivate, bidDetail.userId, history]);

  const getExportShipmentStatusDisabled = useCallback(
    (selectStatus: BidStatus | "all") => {
      const defaultDisabledStatus =
        selectStatus === "committed" ||
        selectStatus === "waiting" ||
        selectStatus === "waitingForExporterInfo";

      if (
        bidDetail.status === "committed" ||
        bidDetail.status === "estimating"
      ) {
        if (defaultDisabledStatus || selectStatus === "inProgress") {
          return true;
        }
        return false;
      }

      if (defaultDisabledStatus) {
        return true;
      }
      return false;
    },
    [bidDetail.status]
  );

  const statusSelectDisabled = useCallback(
    (selectStatus: BidStatus | "all") => {
      const defaultDisabledStatus =
        selectStatus === "committed" ||
        selectStatus === "waiting" ||
        selectStatus === "inProgress";

      if (
        bidDetail.status === "committed" ||
        bidDetail.status === "estimating"
      ) {
        if (
          defaultDisabledStatus ||
          selectStatus === "waitingForExporterInfo"
        ) {
          return true;
        }
        return false;
      }

      if (defaultDisabledStatus) {
        return true;
      }
      return false;
    },
    [bidDetail.status]
  );

  // 현재 상태가 견적 산출 중 이후인 경우에는 견적 산출 중으로 회귀 불가능 (선택 불가)
  const isEstimatingDisabled = (currentStatus: BidStatus) =>
    ESTIMATING_AFTER_STATUS.includes(currentStatus);

  const getStatusDisabled = (optionValue: BidStatus | "all") =>
    isImport
      ? statusSelectDisabled(optionValue)
      : getExportShipmentStatusDisabled(optionValue);

  const disableBidStatusOption = (optionValue: BidStatus | "all") => {
    if (
      optionValue === "estimating" &&
      isEstimatingDisabled(bidDetail.status)
    ) {
      return true;
    }

    return getStatusDisabled(optionValue);
  };

  return (
    <Box display={"grid"} gridTemplateColumns={"repeat(5, 1fr)"}>
      <Box sx={boxLayoutStyle}>
        <Typography>생성일:</Typography>

        <Typography>
          {new Date(bidDetail.createdAt).toLocaleDateString()}
        </Typography>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography>의뢰번호:</Typography>

        <Button variant="text" onClick={handleMoveToTrelloPageClick}>
          {bidDetail.id}
        </Button>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography>회사명:</Typography>

        {bidDetail.team.company ? (
          <Button
            variant="text"
            className="user-company"
            onClick={handleMoveToUserDetailPageClick}
          >
            {bidDetail.team.company}
          </Button>
        ) : (
          <Typography>-</Typography>
        )}
      </Box>

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography>팀명:</Typography>

        {bidDetail?.team.name ? (
          <Button
            variant="text"
            className="user-company"
            onClick={handleMoveToUserDetailPageClick}
          >
            {bidDetail.team.name}
          </Button>
        ) : (
          <Typography>-</Typography>
        )}
      </Box>

      <Box sx={boxLayoutStyle}>
        <Typography>담당자 이름:</Typography>

        <Typography>{bidDetail.user.name}</Typography>
      </Box>

      <Box sx={boxLayoutStyle}>
        <Typography>연락처:</Typography>

        <Typography>{bidDetail.user.phone}</Typography>
      </Box>

      <Box sx={boxLayoutStyle}>
        <Typography>이메일:</Typography>

        <Typography>{bidDetail.user.email}</Typography>
      </Box>

      <Box sx={boxLayoutStyle}>
        <Typography>상태:</Typography>

        <FormControl size="small" sx={{ width: 150 }}>
          <Select
            sx={{
              "& legend": { display: "none" },
              "& fieldset": { top: 0 },
            }}
            value={bidDetail.status}
            onChange={(e) =>
              handleShipmentStatusChangeSelect(e.target.value as BidStatus)
            }
          >
            {BID_STATUS_OPTION_LIST.map((option) => (
              <MenuItem
                disabled={disableBidStatusOption(option.value)}
                key={option.value}
                hidden={option.value === "all"}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box sx={boxLayoutStyle}>
        <Typography>세부 상태:</Typography>

        <Typography>
          {bidDetail.isImport
            ? changeBidProjectStatusNameToKr(bidDetail.projectStatus) || "-"
            : changeExportProjectStatusNameToKr(bidDetail.projectStatus) || "-"}
        </Typography>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography mr={1}>
          {isImport ? "포워딩 담당자" : "수출 포워딩 담당자"}:
        </Typography>

        <Button
          variant="outlined"
          size="small"
          onClick={handleManagerHistoryModalOpen}
        >
          {managerInCharge}
        </Button>
      </Box>

      <Box sx={boxLayoutStyle}>
        <Typography>서비스 지역:</Typography>

        <Typography>
          {bidDetail.locale === "KR" ? "한국" : "싱가포르"}
        </Typography>
      </Box>

      <ManagerHistoryModal
        opensModal={opensManagerHistoryModal}
        onModalClose={() => setOpensManagerHistoryModal(false)}
        managerHistoryList={managerHistoryList}
        adminUserList={adminUserList}
      />

      {showsShipmentStatusChangeToCancelModal && (
        <ShipmentStatusChangeConfirmModal
          setShowsShipmentStatusChangeConfirmModal={
            setShowsShipmentStatusChangeToCancelModal
          }
          showsShipmentStatusChangeConfirmModal={
            showsShipmentStatusChangeToCancelModal
          }
          content={"이 운임을 완전종료하시겠습니까?"}
          onConfirm={handleShipmentStatusChangeToCancel}
        />
      )}

      {showsShipmentStatusChangeToWaitingForExporterInfoModal && (
        <ShipmentStatusChangeConfirmModal
          setShowsShipmentStatusChangeConfirmModal={
            setShowsShipmentStatusChangeToWaitingForExporterInfoModal
          }
          showsShipmentStatusChangeConfirmModal={
            showsShipmentStatusChangeToWaitingForExporterInfoModal
          }
          content={"제출한 견적서를 수락 처리 하시겠습니까?"}
          onConfirm={handleShipmentStatusChangeToWaitingForExporterInfo}
        />
      )}

      {showsExportationShipmentStatusToInprogressModal && (
        <ShipmentStatusChangeConfirmModal
          setShowsShipmentStatusChangeConfirmModal={
            setShowsExportationShipmentStatusToInprogressModal
          }
          showsShipmentStatusChangeConfirmModal={
            showsExportationShipmentStatusToInprogressModal
          }
          // 위와 같은 문구지만 수출은 수락 후 바로 진행중 상태로 변경이 됨
          content={"제출한 견적서를 수락 처리 하시겠습니까?"}
          onConfirm={handleExportationShipmentStatusToInprogress}
        />
      )}
    </Box>
  );
};

export default BasicDesc;

import { useState } from "react";
import { useLocation } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useAtom, useAtomValue } from "jotai";

import { APP_NAME } from "@sellernote/_shared/src/constants";
import { SellernoteAppRegion } from "@sellernote/_shared/src/types/common/common";
import { CorpSizeType } from "@sellernote/_shared/src/types/common/team";
import { ForwardingAdminUserListItem } from "@sellernote/_shared/src/types/forwarding/adminUser";
import { TrelloIssueFilter } from "@sellernote/_shared/src/types/forwarding/trello";

import { useResetAtom } from "jotai/utils";

import {
  FORWARDING_ADMIN_TRELLO_JOTAI_ATOMS,
  FORWARDING_ADMIN_TRELLO_JOTAI_SELECTORS,
} from "../../../../jotaiStates/trello";
import ClearButton from "./ClearButton";

type AppRegionWithAll = SellernoteAppRegion | "all";

const TRELLO_CARD_ISSUE_OPTION_LIST = [
  { label: "필터 없음", value: "none" },
  { label: "ETD 임박", value: "ETDExpiration" },
  { label: "ETA 임박", value: "ETAExpiration" },
  { label: "스케줄 변경", value: "scheduleChanged" },
  { label: "상태 변경", value: "statusChanged" },
  { label: "프리 타임", value: "deadlineFreeTime" },
  { label: "통관 완료", value: "completedCustoms" },
  { label: "계산서 발행", value: "issuedInvoice" },
  { label: "계산서 미발행", value: "unissuedInvoice" },
];

export default function ListHeadFilter({
  salesNameList,
  sessionStorageTrelloIssueFilter,
  onSessionStorageTrelloIssueFilterChange,
}: {
  salesNameList: ForwardingAdminUserListItem[];
  sessionStorageTrelloIssueFilter: TrelloIssueFilter | null;
  onSessionStorageTrelloIssueFilterChange: (
    value: TrelloIssueFilter | null
  ) => void;
}) {
  const { pathname } = useLocation();

  const isExport = pathname.includes("export");

  const [_, setTrelloListFilterData] = useAtom(
    FORWARDING_ADMIN_TRELLO_JOTAI_ATOMS.TRELLO_LIST_FILTER_DATA
  );

  const resetTrelloListFilterData = useResetAtom(
    FORWARDING_ADMIN_TRELLO_JOTAI_ATOMS.TRELLO_LIST_FILTER_DATA
  );

  const fetchTrelloListParams = useAtomValue(
    FORWARDING_ADMIN_TRELLO_JOTAI_SELECTORS.FETCH_TRELLO_LIST_PARAMS
  );

  const [company, setCompany] = useState(fetchTrelloListParams.company || "");
  const [shipmentId, setShipmentId] = useState(
    fetchTrelloListParams.bidId?.toString() || ""
  );
  const [BL, setBL] = useState(fetchTrelloListParams.BL || "");

  const getForwardingManagerName = () => {
    const targetForwardingManager = salesNameList.find(
      (v) => v.id === fetchTrelloListParams.adminId
    );

    if (targetForwardingManager) {
      return targetForwardingManager.name;
    }
    return "all";
  };

  const getForwardingManagerId = (selectValue: string) => {
    const targetForwardingManager = salesNameList.find(
      (v) => v.name === selectValue
    );

    if (targetForwardingManager) {
      return targetForwardingManager.id;
    }
    return undefined;
  };

  const handleSearchClick = ({
    searchTerm,
    searchType,
  }: {
    searchTerm: string | undefined;
    searchType: "BL" | "bidId" | "company";
  }) => {
    sessionStorage.setItem(
      "trelloListFilterData",
      JSON.stringify({
        ...fetchTrelloListParams,
        [searchType]: searchType === "bidId" ? Number(searchTerm) : searchTerm,
      })
    );

    setTrelloListFilterData({
      ...fetchTrelloListParams,
      [searchType]: searchType === "bidId" ? Number(searchTerm) : searchTerm,
    });
  };

  const handleValueClear = ({
    searchType,
  }: {
    searchType: "BL" | "bidId" | "company";
  }) => {
    sessionStorage.setItem(
      "trelloListFilterData",
      JSON.stringify({
        ...fetchTrelloListParams,
        [searchType]: undefined,
      })
    );
    setTrelloListFilterData({
      ...fetchTrelloListParams,
      [searchType]: undefined,
    });
  };

  const resetFilter = () => {
    sessionStorage.setItem(
      "trelloListFilterData",
      JSON.stringify({
        region: "all",
        adminId: 0,
        corpSizeType: "all",
        BL: undefined,
        bidId: undefined,
        company: undefined,
        blKind: "all",
      })
    );

    resetTrelloListFilterData();
  };

  return (
    <Box style={{ display: "flex", alignItems: "flex-end", height: "48px" }}>
      {APP_NAME === "shipda-admin" && (
        <FormControl sx={{ width: 120, mr: 1, ml: 2 }} size="small">
          <InputLabel id="region">담당 국가</InputLabel>

          <Select
            labelId="region"
            value={fetchTrelloListParams.region}
            label="담당 국가"
            onChange={({ target: { value } }) => {
              sessionStorage.setItem(
                "trelloListFilterData",
                JSON.stringify({
                  ...fetchTrelloListParams,
                  region: value as AppRegionWithAll,
                })
              );
              setTrelloListFilterData({
                ...fetchTrelloListParams,
                region: value as AppRegionWithAll,
              });
            }}
          >
            <MenuItem value={"all"}>국가</MenuItem>
            <MenuItem value={"KR"}>한국</MenuItem>
            <MenuItem value={"SG"}>싱가포르</MenuItem>
          </Select>
        </FormControl>
      )}

      <FormControl sx={{ width: 100, mr: 1 }} size="small">
        <InputLabel id="corp-size-type">담당자</InputLabel>

        <Select
          labelId="corp-size-type"
          value={getForwardingManagerName()}
          label="담당자"
          onChange={(e) => {
            sessionStorage.setItem(
              "trelloListFilterData",
              JSON.stringify({
                ...fetchTrelloListParams,
                adminId: getForwardingManagerId(e.target.value),
              })
            );

            setTrelloListFilterData({
              ...fetchTrelloListParams,
              adminId: getForwardingManagerId(e.target.value),
            });
          }}
        >
          <MenuItem value={"all"}>전체</MenuItem>
          {salesNameList.map((v: ForwardingAdminUserListItem) => {
            return (
              <MenuItem key={v.id} value={v.name}>
                {v.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      {APP_NAME === "shipda-admin" && (
        <FormControl sx={{ width: 100, mr: 1 }} size="small">
          <InputLabel id="corp-size-type">세그먼트</InputLabel>

          <Select
            labelId="corp-size-type"
            value={fetchTrelloListParams.corpSizeType}
            label="세그먼트"
            onChange={(e) => {
              sessionStorage.setItem(
                "trelloListFilterData",
                JSON.stringify({
                  ...fetchTrelloListParams,
                  corpSizeType: e.target.value as CorpSizeType | "all",
                })
              );

              setTrelloListFilterData({
                ...fetchTrelloListParams,
                corpSizeType: e.target.value as CorpSizeType | "all",
              });
            }}
          >
            <MenuItem value={"all"}>전체</MenuItem>
            <MenuItem value={"SME"}>SME</MenuItem>
            <MenuItem value={"BIG"}>BIG</MenuItem>
          </Select>
        </FormControl>
      )}

      {APP_NAME === "shipda-admin" && (
        <FormControl sx={{ width: 100, mr: 5 }} size="small">
          <InputLabel id="bl-type">BL 유형</InputLabel>

          <Select
            labelId="bl-type"
            value={fetchTrelloListParams.blKind}
            label="BL 유형"
            onChange={(e) => {
              sessionStorage.setItem(
                "trelloListFilterData",
                JSON.stringify({
                  ...fetchTrelloListParams,
                  blKind: e.target.value,
                })
              );

              setTrelloListFilterData({
                ...fetchTrelloListParams,
                blKind: e.target.value,
              });
            }}
          >
            <MenuItem value={"all"}>전체</MenuItem>
            <MenuItem value={"surrender"}>SBL</MenuItem>
            <MenuItem value={"original"}>OBL</MenuItem>
          </Select>
        </FormControl>
      )}

      <TextField
        label="회사명"
        variant="outlined"
        size="small"
        value={company}
        onChange={(e) => {
          setCompany(e.target.value);
          /** 클리어버튼 없이 직접 입력어를 지울 때 갱신 */
          if (!e.target.value) {
            handleSearchClick({
              searchTerm: undefined,
              searchType: "company",
            });
          }
        }}
        style={{ width: 150 }}
        onKeyDown={(ev) => {
          if (ev.key === "Enter")
            handleSearchClick({ searchTerm: company, searchType: "company" });
        }}
        InputProps={{
          endAdornment: (
            <ClearButton
              isVisible={Boolean(company)}
              onValueClear={() => {
                handleValueClear({ searchType: "company" });
                setCompany("");
              }}
            />
          ),
        }}
      />

      <IconButton
        onClick={() =>
          handleSearchClick({ searchTerm: company, searchType: "company" })
        }
      >
        <SearchIcon />
      </IconButton>

      <TextField
        label="의뢰번호 입력"
        variant="outlined"
        size="small"
        value={shipmentId}
        onChange={(e) => {
          const value = e.target.value;
          // 숫자 이외의 문자 제거
          const filteredValue = value.replace(/[^0-9]/g, "");
          setShipmentId(filteredValue);

          /** 클리어버튼 없이 직접 입력어를 지울 때 갱신 */
          if (!e.target.value) {
            handleSearchClick({
              searchTerm: undefined,
              searchType: "bidId",
            });
          }
        }}
        style={{ width: 150 }}
        onKeyDown={(ev) => {
          if (ev.key === "Enter")
            handleSearchClick({ searchTerm: shipmentId, searchType: "bidId" });
        }}
        InputProps={{
          endAdornment: (
            <ClearButton
              isVisible={Boolean(shipmentId)}
              onValueClear={() => {
                handleValueClear({ searchType: "bidId" });
                setShipmentId("");
              }}
            />
          ),
        }}
      />

      <IconButton
        onClick={() =>
          handleSearchClick({
            searchTerm: shipmentId,
            searchType: "bidId",
          })
        }
      >
        <SearchIcon />
      </IconButton>

      <TextField
        label="BL번호 입력"
        variant="outlined"
        size="small"
        value={BL}
        onChange={(e) => {
          setBL(e.target.value);

          /** 클리어버튼 없이 직접 입력어를 지울 때 갱신 */
          if (!e.target.value) {
            handleSearchClick({
              searchTerm: undefined,
              searchType: "BL",
            });
          }
        }}
        style={{ width: 170 }}
        onKeyDown={(ev) => {
          if (ev.key === "Enter")
            handleSearchClick({ searchTerm: BL, searchType: "BL" });
        }}
        InputProps={{
          endAdornment: (
            <ClearButton
              isVisible={Boolean(BL)}
              onValueClear={() => {
                handleValueClear({ searchType: "BL" });
                setBL("");
              }}
            />
          ),
        }}
      />

      <IconButton
        onClick={() => handleSearchClick({ searchTerm: BL, searchType: "BL" })}
      >
        <SearchIcon />
      </IconButton>

      <FormControl sx={{ width: 150, mr: 1, ml: 2 }} size="small">
        <InputLabel>이슈 필터</InputLabel>

        <Select
          label="이슈 필터"
          value={sessionStorageTrelloIssueFilter || "none"}
          onChange={({ target: { value } }) => {
            onSessionStorageTrelloIssueFilterChange(value as TrelloIssueFilter);
          }}
        >
          {TRELLO_CARD_ISSUE_OPTION_LIST.filter(({ value }) => {
            /** 파트너 Admin/수출은 선택적으로 필터 사용 */
            if (APP_NAME === "partner-admin") {
              return value === "completedCustoms" || value === "none";
            }

            if (isExport) {
              return (
                value === "statusChanged" ||
                value === "scheduleChanged" ||
                value === "issuedInvoice" ||
                value === "unissuedInvoice" ||
                value === "none"
              );
            }

            return true;
          }).map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Button
        variant="outlined"
        color="primary"
        onClick={resetFilter}
        sx={{
          wordBreak: "keep-all",
          paddingTop: "6.75px",
          paddingBottom: "6.75px",
        }}
      >
        초기화
      </Button>
    </Box>
  );
}
